.card {
    background: var(--surface-card);
    padding: 2rem;
    border-radius: 10px;
    margin-bottom: 1rem;
}

/* .flex{
  display: flex!important;
  
}

.justify-content-between{
  justify-content: space-between!important;
} */

.text-green-500{
    color: green!important;
}

.text-red-500{
    color: red!important;
}

.p-dialog-header{
    background-color:rgb(44 56 148)!important;
    padding: .8rem!important;
}

.p-dialog-title{
    color: white!important;
    font-weight: 1000!important;
    font-size: 1rem!important;
}

.p-dialog-mask {
    background-color: #0000001c!important;
}

.p-dialog-content{
    padding: 2rem 1.5rem 2rem 1.5rem!important;
}

@media (min-width: 1200px) {
    .outcome-dialog {
      width: 35vw; 
    }
  }
  
  @media (max-width: 767px) {
    .outcome-dialog {
      width: 90vw;
    }
  }
  
  @media (min-width: 768px) and (max-width: 980px) {
    .outcome-dialog {
      width: 80vw;
    }
  }

.rectangle-box {
    display: flex;
    align-items: center;
    margin-bottom: 9px;
    border: 1px solid rgba(128, 128, 128, 0.557);
    cursor: pointer;
    position: relative; /* Position relative for pseudo-element */
    overflow: hidden; /* Hide overflowing pseudo-element */
  }

  .rectangle-box-green {
    display: flex;
    align-items: center;
    margin-bottom: 9px;
    border: 1px solid rgb(27, 196, 120);
    cursor: pointer;
    position: relative; /* Position relative for pseudo-element */
    overflow: hidden; /* Hide overflowing pseudo-element */
  }
  .rectangle-box-yellow {
    display: flex;
    align-items: center;
    margin-bottom: 9px;
    border: 1px solid rgb(255 232 0);
    cursor: pointer;
    position: relative; /* Position relative for pseudo-element */
    overflow: hidden; /* Hide overflowing pseudo-element */
  }
  .rectangle-box-purple {
    display: flex;
    align-items: center;
    margin-bottom: 9px;
    border: 1px solid rgb(114 0 240);
    cursor: pointer;
    position: relative; /* Position relative for pseudo-element */
    overflow: hidden; /* Hide overflowing pseudo-element */
  }
  .rectangle-box-red {
    display: flex;
    align-items: center;
    margin-bottom: 9px;
    border: 1px solid rgb(255 0 0);
    cursor: pointer;
    position: relative; /* Position relative for pseudo-element */
    overflow: hidden; /* Hide overflowing pseudo-element */
  }

  .rectangle-box-blue {
    display: flex;
    align-items: center;
    margin-bottom: 9px;
    border: 1px solid rgb(2, 217, 255);
    cursor: pointer;
    position: relative; /* Position relative for pseudo-element */
    overflow: hidden; /* Hide overflowing pseudo-element */
  }
  
  .box-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background-color: rgb(27, 196, 120)
  }
  .box-logo-green {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background-color: rgb(27, 196, 120)
  }
  .box-logo-yellow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background-color: rgb(255 232 0);
  }
  .box-logo-purple {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background-color: rgb(114 0 240);
  }
  .box-logo-red {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background-color: rgb(255 0 0);
  }

  .box-logo-blue {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background-color: rgb(2, 217, 255);
  }

  .box-logo span {
    max-width: 100%; 
    max-height: 100%; 
    display: block;
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: x-large;
  }

  .box-logo-green span {
    max-width: 100%; 
    max-height: 100%; 
    display: block;
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: x-large;
  }
  .box-logo-yellow span {
    max-width: 100%; 
    max-height: 100%; 
    display: block;
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: x-large;
  }
  .box-logo-purple span {
    max-width: 100%; 
    max-height: 100%; 
    display: block;
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: x-large;
  }
  .box-logo-red span {
    max-width: 100%; 
    max-height: 100%; 
    display: block;
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: x-large;
  }

  .box-logo-blue span {
    max-width: 100%; 
    max-height: 100%; 
    display: block;
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: x-large;
  }
  
  .box-content {
    flex: 1;
    background-color: white;
    height: 50px;
    padding-left: 1rem!important;
    transition: background-color 0.3s ease-in-out;
  }

  .box-content-selected{
    background-color: rgb(27, 196, 120);
    color: white;
    font-weight: bold;
  }
  .box-content-selected-green{
    background-color: rgb(27, 196, 120);
    color: white;
    font-weight: bold;
  }
  .box-content-selected-yellow{
    background-color: rgb(255 232 0);
    color: white;
    font-weight: bold;
  }
  .box-content-selected-purple{
    background-color: rgb(114 0 240);
    color: white;
    font-weight: bold;
  }
  .box-content-selected-red{
    background-color: rgb(255 0 0);
    color: white;
    font-weight: bold;
  }

  .box-content-selected-blue{
    background-color: rgb(2, 217, 255);
    color: white;
    font-weight: bold;
  }

  .rectangle-box:hover .box-content {
    background-color: rgb(27, 196, 120);
    color: white;
    font-weight: bold;
  }

  .rectangle-box-green:hover .box-content {
    background-color: rgb(27, 196, 120);
    color: white;
    font-weight: bold;
  }
  .rectangle-box-yellow:hover .box-content {
    background-color: rgb(255 232 0);
    color: white;
    font-weight: bold;
  }
  .rectangle-box-purple:hover .box-content {
    background-color: rgb(114 0 240);
    color: white;
    font-weight: bold;
  }
  .rectangle-box-red:hover .box-content {
    background-color: rgb(255 0 0);
    color: white;
    font-weight: bold;
  }

  .rectangle-box-blue:hover .box-content {
    background-color: rgb(2, 217, 255);
    color: white;
    font-weight: bold;
  }


  .callNotes-container{
    margin-top: 2rem;
  }

  .callNotes-heading{
    margin-bottom: 3px!important;
  }

  .p-inputtext:enabled:hover {
    border-color:  rgb(44 56 148)!important;
}

.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #c7feeb!important;
  border-color: rgb(44 56 148)!important;
}

.submit-buttons-container{
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.cancel-button{
  margin-right: .5rem!important;
  height: 35px;
  border: none!important;
  background-color: rgba(128, 128, 128, 0.735)!important;
}

.record-button{
  height: 35px;
  border: none!important;
  background-color: rgb(44 56 148)!important;
}

.set-outcome-button{
  height: 35px;
  border: none!important;
  background-color: rgb(44 56 148)!important;

}

.set-redirect-button{
  height: 35px;
  border: none!important;
  background-color: rgb(72, 148, 44)!important;

}

.outcome-null{
  width: 100%;
    height: 69px;
    text-align: center;
    padding-top: 26px;
}
.outcome-Sale{
  width: 100%;
    height: 69px;
    color: white;
    background-color: rgb(27, 196, 120);
    text-align: center;
    padding-top: 26px;
}
.outcome-No.Sale{
  width: 100%;
    height: 69px;
    color: white;
    background-color: rgb(255 232 0);
    text-align: center;
    padding-top: 26px;
}
.outcome-Value\/Coaching{
  width: 100%;
    height: 69px;
    color: white;
    background-color: rgb(114 0 240);
    text-align: center;
    padding-top: 26px;
}
.outcome-Did.Not.Show{
  width: 100%;
    height: 69px;
    color: white;
    background-color: rgb(255 0 0);
    text-align: center;
    padding-top: 26px;
}

.outcome-Reschedule{
  width: 100%;
    height: 69px;
    color: white;
    background-color: rgb(2, 217, 255);
    text-align: center;
    padding-top: 26px;
}

.padding-0{
  padding: 0px!important;
  /* text-align: center!important; */
}


.p-datatable .p-column-header-content {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.header-container{
  display: flex;
  justify-content: space-between;
}

.filter-container{
  display: flex;
  gap: 1rem;
}