.nav-button{
    color: #6366F1!important;
    background: #ffffff!important;
    transition: box-shadow 0.2s!important;
    /* border: none!important; */
}

.nav-button-active{
    background: #6366F1!important;
    color: #ffffff!important;
    transition: box-shadow 0.2s!important;
    /* border: none!important; */
}

.nav-button:hover{
    background: #6366F1!important;
    color: #ffffff!important;
    transition: box-shadow 0.2s!important;
}

.nav-button-active:hover{
    background: #6366F1!important;
    color: #ffffff!important;
    transition: box-shadow 0.2s!important;
}

.header-flex{
    display: flex;
    justify-content: flex-start;
    gap: 2rem;
    padding-left: 1rem;
}